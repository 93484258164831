var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.cardAttendance},[_c('a-card',{class:[_vm.$style.cardAttendanceContent],style:({
    borderColor: !_vm.allIsAttend && _vm.attendClick ? _vm.currentBorderColor : '#41B883',
    height: _vm.isMobile ? '6.5rem' : '6rem',
   }),on:{"click":function($event){$event.preventDefault();return _vm.changeColor.apply(null, arguments)}}},[_c('a-row',[_c('a-col',{attrs:{"span":_vm.isMobile ? 7 : 5}},[_c('img',{style:({
          height: _vm.isMobile ? '40px' : '50px',
          width: _vm.isMobile ? '40px' : '50px',
        }),attrs:{"src":require("@/assets/no-image-icon-hi.png"),"alt":"no-user"}})]),_c('a-col',{attrs:{"span":_vm.isMobile ? 0 : 2}}),(_vm.student.murid)?_c('a-col',{attrs:{"span":17}},[_c('div',{class:_vm.$style.boxEllipsis},[_c('b',[_vm._v(_vm._s(_vm.student.murid.nama))])]),_c('div',{style:({color: _vm.percentageColor})},[_vm._v(_vm._s(_vm.attendancePercentage)+"%")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }