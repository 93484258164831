<template>
  <div>
    <div style="margin-bottom: 30px">
      <a-divider orientation="left">
        <h3>Topic & Sub-Topic</h3>
      </a-divider>
      <div class="textMaterial">
        <p>
          <b>{{ materials.nama ? materials.nama : "-" }}</b>
        </p>
        <p>{{ materials.subtopik ? materials.subtopik : "-" }}</p>
        <!-- {{materials}} -->
      </div>
    </div>
    <div style="margin-bottom: 12%">
      <a-divider orientation="left">
        <h3>Video</h3>
      </a-divider>
      <div class="d-block d-lg-none">
        <div
          v-for="item in videoUrls"
          :key="item"
          class="mb-4 embed-responsive embed-responsive-16by9"
        >
          <iframe
            class="embed-responsive-item"
            :src="item"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <!-- <div class="d-block d-lg-none">
        <div v-for="item in videoUrls" :key="item">
          <iframe
            class="w-100"
            height="300"
            allowfullscreen
            :src="item"
          ></iframe>
        </div>
      </div> -->
      <a-carousel
        class="carousel d-none d-lg-block"
        v-if="videoUrls && videoUrls.length"
        arrows
        dots-class="slick-dots slick-thumb"
      >
        <a slot="customPaging" slot-scope="props">
          <!-- <img :src="getImgUrl(props.i)" /> -->
          <iframe :src="videoUrls[props.i]"></iframe>
        </a>
        <div v-for="item in videoUrls" :key="item">
          <iframe width="750" height="300" allowfullscreen :src="item"></iframe>
          <!-- <img :src="baseUrl + 'abstract0' + item + '.jpg'" /> -->
        </div>
      </a-carousel>
      <EmptyState
        class="my-5"
        v-else
        heading="No Data"
        description="This session have no video datas"
      />
    </div>
    <div
      style="margin-bottom: 30px; display: inline-block; width: 100%"
      class="mt-lg-5"
    >
      <a-divider orientation="left">
        <h3>Reading Materials</h3>
      </a-divider>
      <div
        class="textMaterial"
        v-if="materials.brief && materials.brief !== ''"
      >
        <span ref="brief" v-html="materials.brief"></span>
      </div>
      <EmptyState
        class="my-5"
        v-else
        heading="No Data"
        description="This session have no materials"
      />
    </div>
    <div style="margin-bottom: 40px">
      <a-row type="flex" align="middle" justify="end">
        <a-col :span="!isDesktop ? 24 : fileMaterials.length ? 20 : 24">
          <a-divider orientation="left">
            <h3>Attached Materials</h3>
          </a-divider>
        </a-col>

        <a-col
          :span="!isDesktop ? 24 : fileMaterials.length ? 4 : 0"
          :style="{
            display: 'flex',
            'justify-content': !isDesktop ? 'center' : 'flex-end',
            'align-self': !isDesktop ? 'center' : 'flex-end',
          }"
        >
          <a-button
            class="btnDownload"
            shape="round"
            type="primary"
            icon="download"
            @click.prevent="downloadAll"
            >DOWNLOAD ALL</a-button
          >
        </a-col>
      </a-row>
      <div v-if="fileMaterials && fileMaterials.length">
        <div
          style="margin-bottom: 10px"
          class="textMaterial mt-3 mt-lg-0"
          v-for="(item, index) in fileMaterials"
          :key="index"
        >
          <a
            target="_blank"
            download
            :href="`${config.apiUrl}/${item.filepath}${item.filename}`"
          >
            {{ item.originalname }}
            <a-icon type="download" />
          </a>
        </div>
      </div>
      <EmptyState
        class="my-5"
        v-else
        heading="No Data"
        description="This session have no attached materials"
      />
    </div>
  </div>
</template>
<script>
import { renderMathMl } from '@/helpers/mathjax'
import EmptyState from '@/components/app/EmptyState'
import config from '@/config'
const baseUrl =
  'https://raw.githubusercontent.com/vueComponent/ant-design-vue/master/components/vc-slick/assets/img/react-slick/'
export default {
  props: ['materials', 'videoUrls', 'fileMaterials'],
  components: {
    EmptyState,
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
  methods: {
    downloadAll() {
      this.fileMaterials.forEach(file => {
        window.open(`${config.apiUrl}/${file.filepath}${file.filename}`, '_blank')
      })
    },
    getImgUrl(i) {
      return `${baseUrl}abstract0${i + 1}.jpg`
    },
  },
  data() {
    return {
      baseUrl,
      config,
    }
  },
  mounted() {
    let arrayOfRef = []
    const brief = this.$refs.brief
    if (brief) {
      if (Array.isArray(brief)) arrayOfRef = arrayOfRef.concat(brief)
      else arrayOfRef.push(brief)

      renderMathMl(arrayOfRef)
    }
  },
}
</script>
<style scoped>
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide iframe {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 50%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -45%;
}
.ant-carousel >>> .slick-thumb li {
  width: 10%;
  height: 100%;
}
.ant-carousel >>> .slick-thumb li iframe {
  width: 100%;
  max-height: 100px;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active iframe {
  filter: grayscale(0%);
}

.textMaterial {
  font-family: "Mukta", "sans-serif";
  font-weight: 500;
  font-size: 16px;
  margin: auto;
  width: 65%;
}

@media (max-width: 769px) {
  .textMaterial {
    width: 100%;
  }
}

.btnDownload {
  margin-left: 5%;
}

.paddingLeftWrapper {
  padding-left: 5% !important;
}

.iframeChoice {
  content: "";
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 110;
  top: 0;
  left: 0;
  position: absolute;
}

.ant-carousel .slick-thumb li a::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
</style>
