<template>
  <div :class="$style.cardAttendance">
    <a-card :class="[$style.cardAttendanceContent]" :style="{
      borderColor: !allIsAttend && attendClick ? currentBorderColor : '#41B883',
      height: isMobile ? '6.5rem' : '6rem',
     }" @click.prevent="changeColor">
      <a-row>
        <a-col :span="isMobile ? 7 : 5">
          <img src="@/assets/no-image-icon-hi.png" alt="no-user" :style="{
            height: isMobile ? '40px' : '50px',
            width: isMobile ? '40px' : '50px',
          }">
        </a-col>
        <a-col :span="isMobile ? 0 : 2"></a-col>
        <a-col :span="17" v-if="student.murid">
          <div :class="$style.boxEllipsis"><b>{{student.murid.nama}}</b></div>
          <div :style="{color: percentageColor}">{{attendancePercentage}}%</div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>
<script>
export default {
  methods: {
    changeColor() {
      this.$store.commit('attendance/SET_STATE', {
        allIsAttend: false,
      })

      if (!this.attendClick) {
        this.currentStatus = this.attendanceStatus[2].status
        this.currentBorderColor = this.attendanceStatus[2].borderColor
        this.attendClick = true
      } else {
        for (let i = 0; i < this.attendanceStatus.length; i++) {
          const status = this.attendanceStatus[i]
          if (status.status === this.currentStatus) {
            if (i === this.attendanceStatus.length - 1) {
              this.currentStatus = this.attendanceStatus[0].status
              this.currentBorderColor = this.attendanceStatus[0].borderColor
              break
            } else {
              this.currentStatus = this.attendanceStatus[i + 1].status
              this.currentBorderColor = this.attendanceStatus[i + 1].borderColor
              break
            }
          }
        }
      }

      this.$store.dispatch('attendance/UPDATE_SINGLE_ABSENSI', {
        idKelasTahunAjaran: this.student.id,
        tanggalSesi: this.session.tanggal_sesi,
        status: this.currentStatus,
        idSesi: this.session.id,
      })
        .then(data => {
          if (data) {
            // console.log('oke')
          }
        })
    },
  },
  data() {
    return {
      attendanceStatus: [
        { status: 'default', borderColor: '#E3EAF7' },
        { status: 'present', borderColor: '#41B883' },
        { status: 'absent', borderColor: '#FF0000' },
        { status: 'sick', borderColor: 'blue' },
        { status: 'excused', borderColor: 'yellow' }],
      currentStatus: '',
      currentBorderColor: '',
      attendClick: true,
    }
  },
  props: ['attendancePercentage', 'student', 'session'],
  computed: {
    percentageColor() {
      // console.log(this.attendancePercentage, 'ini adalahs')
      if (this.attendancePercentage < 25) {
        return '#FF0000'
      } else if (this.attendancePercentage >= 25 && this.attendancePercentage < 50) {
        return '#FF8811'
      } else if (this.attendancePercentage >= 50 && this.attendancePercentage < 75) {
        return '#F9E900'
      } else if (this.attendancePercentage >= 75 && this.attendancePercentage < 100) {
        return '#7ABE28'
      } else {
        return '#41B883'
      }
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    allIsAttend: {
      get: function() {
        return this.$store.state.attendance.allIsAttend
      },
    },
  },
  created() {
    const absensi = this.student.status_kehadiran
    // console.log(absensi, this.student)
    if (absensi) {
      if (absensi === 'present') {
        this.currentStatus = 'present'
        this.currentBorderColor = '#41B883'
      } else if (absensi === 'absent') {
        this.currentStatus = 'absent'
        this.currentBorderColor = '#FF0000'
      } else if (absensi === 'sick') {
        this.currentStatus = 'sick'
        this.currentBorderColor = 'blue'
      } else if (absensi === 'excused') {
        this.currentStatus = 'excused'
        this.currentBorderColor = 'yellow'
      }
    } else {
      this.currentStatus = 'default'
      this.currentBorderColor = '#E3EAF7'
    }
  },
  watch: {
    allIsAttend(newVal, oldVal) {
      if (newVal === true) {
        this.attendClick = false
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
